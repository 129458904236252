import React from "react"
import Button from "../components/Button/button"

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>Kahjuks sellist lehte ei leitud....</h1>
        <Button
          cta={"Mine tagasi avalehele"}
          label="Mine tagasi avalehele"
          anchor={true}
          href={"/"}
        />
      </div>
    </div>
  )
}

export default NotFoundPage
